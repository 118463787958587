import React from 'react'
import { Container } from '../components/Container'
import { SEO } from '../components/SEO'
import { Typography } from '../components/Typography'

const FirstNumber = () => {
  return (
    <li>
      <b>Acceptance of Terms</b>
      <ol>
        <li>
          The Recon Group LLP, dba ReturnPro (“<b>ReturnPro</b>” or “<b>we</b>” or “<b>us</b>” or “
          <b>our</b>”) offers the Services (as defined below) to you (“<b>User</b>” or “<b>you</b>”
          or “<b>your</b>”) subject to these Terms of Service and User Agreement (“
          <b>TOS</b>”). Each of ReturnPro and User may be referred to herein as a “Party” and
          collectively, the “Parties”. Please note that your use of the Site as defined below, is
          also governed by our Privacy Policy, as well as all other applicable terms, conditions,
          limitations and requirements contained on the ReturnPro Site, all of which (as changed
          over time) are incorporated into the TOS. In addition, when using certain Services, User
          will be subject to the fee schedule (“<b>Fee Schedule</b>”) that governs the operation of
          such Services which are posted on the Site or are made available to registered users of
          the Service. All such Fee Schedules are hereby incorporated by reference.{' '}
        </li>
        <li>
          By accepting this TOS or by accessing or using the Service or ReturnPro’s website
          www.returnpro.com (the “<b>Site</b>”), you acknowledge that you have read, understood, and
          agreed to be bound by this TOS.
        </li>
        <li>
          In order to access the Site and participate in the Service, you will have to create a
          member account (&quot;<b>Account</b>&quot;). When you create your Account, you will be
          required to agree to this TOS. If you choose to register for the Service, you agree to
          provide and maintain true, accurate, current and complete information about yourself. You
          are responsible for maintaining the confidentiality of User’s login, password and account
          and for all activities that occur under your login or account. Registration data and
          certain other information about you are governed by our Privacy Policy.
        </li>
        <li>
          Upon accepting this TOS, you are solely responsible for all data, information, text,
          content and other materials uploaded, posted, delivered, provided or otherwise transmitted
          or stored (hereafter “<b>post(ing)</b>”) in connection with or relating to the Service (“
          <b>User Content</b>”). For every email sent outside of User organization via the Services,
          you acknowledge and agree that ReturnPro shall have the right to automatically add an
          identifying footer in accordance with our standard policies then in effect.
        </li>
        <li>
          You agree to cooperate with and provide reasonable assistance to ReturnPro in promoting
          and advertising the Services.
        </li>
        <li>
          ReturnPro reserves the right to access User’s account in order to respond to User requests
          for technical support.
        </li>
        <li>
          By posting User Content on or through the Service, you hereby grant ReturnPro a worldwide,
          non-exclusive, perpetual, irrevocable, royalty-free, fully paid, sublicensable and
          transferable license to use, modify, reproduce, distribute, display, publish and perform
          User Content in connection with the Service. ReturnPro has the right, but not the
          obligation, to monitor the Service, or User Content. You further agree that ReturnPro may
          remove or disable the User Content at any time for any reason (including, but not limited
          to, upon receipt of claims or allegations from third parties or authorities relating to
          such User Content), or for no reason at all.
        </li>
        <li>
          The Site and Service are not intended to be accessed or used by anyone under the age of
          18, and anyone under the age of 18 is strictly prohibited from accessing the Site using
          the Service. ReturnPro does not knowingly collect personally identifiable information from
          users under the age of 18. You represent and warrant that you: (a) are at least 18 years
          of age; (b) have not previously been suspended or removed from accessing the Site and
          using the Service; (c) have full power and authority to enter into this agreement and in
          doing so will not violate any other agreement to which you are a party; (d) are not
          located in, under the control of, or a national or resident of any country to which the
          United States has embargoed goods or services; (e) are not identified as a &quot;Specially
          Designated National;&quot; (f) are not placed on the Commerce Department&apos;s Denied
          Persons List; and (g) will not use the Service if the laws of your country prohibit you
          from doing so in accordance with these Terms.
        </li>
        <li>
          If you are entering into this TOS on behalf of a company, organization, business or other
          legal entity (“<b>Entity</b>”), you represent that you have the authority to bind such
          Entity and its affiliates to this TOS, in which case the term User shall also refer to
          such Entity and its affiliates. If you do not have such authority, or if you do not agree
          with this TOS, you must not accept this TOS and may not use the Service.
        </li>
        <li>
          ReturnPro reserves the right, at any time: (1) to modify or update any of the terms of
          this TOS, said modification or update being effective upon its posting on the Site; (2) to
          suspend or interrupt the access to or the operation of the Service, or any portion of it,
          as necessary to perform maintenance and/or for any other purpose; and/or (3) to modify or
          change the Service, or any portion of it.{' '}
          <b>
            USER’S CONTINUED USE OF THE SERVICE SHALL BE CONSIDERED USER ACCEPTANCE TO THE REVISED
            TOS. SHOULD USER OBJECT TO ANY OF THE TERMS OF THIS TOS OR ANY SUBSEQUENT MODIFICATIONS,
            USER ONLY RECOURSE IS TO IMMEDIATELY DISCONTINUE THE USE OF THE SERVICE.
          </b>
        </li>
        <li>
          In addition to the TOS, User access and use of the Service may be subject to terms and
          conditions and other policies of third-party service providers, including digital store or
          marketplace providers (“<b>Platform(s)</b>” and “<b>Platform Providers</b>”, as
          applicable). The Service may be available with various such Platform Providers, as
          determined by ReturnPro at its sole discretion from time to time. Accordingly, the use of
          the Service accessed through such Platform Providers is subject to the applicable terms
          and conditions governing the access and use of which shall prevail with respect to the use
          of such Platform.
        </li>
      </ol>
    </li>
  )
}

const TwoNumber = () => {
  return (
    <li>
      <b>Description of Services</b>
      <ol>
        <li>
          The “<b>Service(s)</b>” means and includes (a) the Site, and (b) Warehouse and Fulfillment
          Services (as defined below). Any new features added to or augmenting the Service are also
          subject to this TOS. ReturnPro provides an end-to-end return management platform which
          enables merchants, or sellers or record, to enhance their buying customers experience. The
          ReturnPro Service is made available to merchants, or sellers of record, through a fully
          integrated suite of software dashboards. ReturnPro may also include any software or API
          that is made available to through third party Platform providers.
        </li>
        <li>
          Subject to this TOS, the Privacy Policy, and your successful completion of the user
          registration process and creation of an Account, ReturnPro hereby grants to you during the
          Term, a non-sub licensable, non-transferable, non-exclusive license to access the Site and
          to use the Services and the information and materials displayed by Return Pro on the Site,
          solely for your personal or individual and internal business use. The ReturnPro products,
          Services, and features, and the content, text, documents, descriptions, products,
          graphics, photos, sounds, videos, interactive features, trademarks, service marks and
          logos, software data, reports, text, images, contained in or made available through the
          Service are exclusively owned by ReturnPro.
        </li>
        <li>
          The TOS shall become effective on the date of acceptance as set forth in Section 1.2 (the
          “<b>Effective Date</b>”) and shall continue until terminated in accordance with this TOS
          (the “<b>Term</b>”). The ReturnPro Service is provided on a recurring monthly basis
          starting on the Effective Date. The Service shall automatically renew for successive
          one-month periods unless either Party provides the other with at least thirty (30) days
          written notice prior to the end of the then-current month of its intent not to renew.
        </li>
        <li>
          To the extent you provide any suggestions, enhancement requests, feedback, recommendations
          or other input relating to the Service (“<b>Feedback</b>”), you hereby grant ReturnPro the
          full, unencumbered right to use, incorporate and otherwise fully exercise and exploit any
          such Feedback in connection with its products and services generally.
        </li>
        <li>
          ReturnPro shall have the right to collect and analyze data and other information relating
          to the provision, use and performance of various aspects of the Services and related
          systems and technologies (including, without limitation, information concerning User
          Content and data derived therefrom), and ReturnPro will be free (during and after the term
          hereof) to (i) use such information and data to improve and enhance the Services and for
          other development, diagnostic and corrective purposes in connection with the Services and
          other ReturnPro offerings, and (ii) disclose such data solely in aggregate or other
          de-identified form in connection with its business. ReturnPro may obtain information,
          including personal data, from third parties, you and other users, and combine that with
          information ReturnPro collects through the Service, such as in the case where you use
          Platforms or other third party services in connection with the Service (a “
          <b>Third Party Service</b>”), or where we may have access to certain information from a
          third party social media or authentication service, if you log into our Services through
          such third party service or otherwise provide us with access to information from such
          service. Any access that we may have to such information from a third party social media
          or third party authentication service is in accordance with the authorization procedures
          determined by that service. By authorizing ReturnPro to connect with a Third Party
          Service, User authorizes ReturnPro to access and store User name, email address(es),
          current city, profile picture URL, and other personal data that the Third Party Service
          makes available to ReturnPro, and to use and disclose it in accordance with the TOS and
          ReturnPro Privacy Policy. User’s use of the Third Party Services is subject to, and User
          is responsible for compliance with, all applicable terms and conditions of the relevant
          Third Party Services provider. Please refer to the terms and conditions from the
          applicable Third Party Services.
        </li>
        <li>
          You understand that the operation of the Service, including User Content, may be
          unencrypted and involve (a) transmissions over various networks; (b) changes to conform
          and adapt to technical requirements of connecting networks or devices and (c) transmission
          to ReturnPro’s third party vendors and hosting partners to provide the necessary delivery,
          storage, fulfillment, hardware, software, networking, and related technology required to
          operate and maintain the Service. Accordingly, you acknowledge that you bear sole
          responsibility for adequate security, protection and backup of User Content. ReturnPro
          will have no liability to User for any unauthorized access or use of any of User Content,
          or any corruption, deletion, destruction or loss of any of User Content.
        </li>
        <li>
          Users are responsible for obtaining and maintaining any equipment and ancillary services
          needed to connect to, access or otherwise use the Services, including, without limitation,
          modems, hardware, server, software, operating system, networking, web servers, long
          distance and local telephone service (collectively, “<b>Equipment</b>”). You shall be
          responsible for ensuring that such Equipment is compatible with the Services and complies
          with all configurations and specifications set forth in ReturnPro’s published policies
          then in effect. You shall also be responsible for maintaining the security of the
          Equipment, User Account, passwords (including but not limited to administrative and user
          passwords) and files, and for all uses of User account or the Equipment with or without
          User knowledge or consent.
        </li>
        <li>
          ReturnPro reserves the right to use your name and/or company name as a reference for
          marketing or promotional purposes on ReturnPro’s website and in other communication with
          existing or potential ReturnPro customers. To decline ReturnPro this right you need to
          email{' '}
          <a className="underline" href="mailto:support@returnpro.com">
            support@returnpro.com
          </a>{' '}
          stating that you wish not to be used as a reference.
        </li>
        <li>
          The ReturnPro Service may contain links to other websites or resources for the convenience
          of Users, features and functionalities that may link a User or provide Users with access
          to third party content which is completely independent of ReturnPro, including Platforms,
          websites, directories, servers, networks, systems, information and databases,
          applications, software, programs, products or services, and the Internet in general. You
          acknowledge and agree that ReturnPro is not responsible or liable for (i) the availability
          or accuracy of such sites or resources or (ii) the content, advertising or products on or
          available from such sites or resources, the agreements that may govern the use of such
          sites or resources, or the privacy policies and data collection, use or retention
          practices of such sites. The inclusion of any link on the Site will not imply that
          ReturnPro endorses the linked site. All use of the links is done at User’s own risk. If
          you enable, access or use any Platforms or other Third Party Services in connection with
          the Service, you agree that your access and use of such Third Party Services is governed
          by the terms and conditions of such Third Party Services, and ReturnPro does not endorse,
          is not responsible or liable for, and makes no representations as to any aspect of such
          Third Party Services, including, without limitation, the accuracy, completeness or
          authenticity of the information contained or the manner in which the service providers of
          such Third Party Services handle data, including User data, User Content, or any
          interaction between User and Third Party Services and its respective service providers.
          You irrevocably waive any claim against ReturnPro with respect to such Third Party
          Services. ReturnPro is not liable for any damage or loss caused or alleged to be caused by
          or in connection with User enablement, access or use of any such Third Party Services, or
          User reliance on the privacy practices, data security processes or other policies of such
          Third Party Services. You may be required to register for or log into such Third Party
          Services on their respective websites. User interactions with organizations or individuals
          found on or through the Service or Third Party Services are solely between you and such
          organizations or individuals. You should make whatever investigation necessary or
          appropriate before proceeding with any interaction with any of the third parties providing
          Third Party Services. You further agree that ReturnPro is not responsible or liable for
          any loss or damage of any kind or nature incurred as the result of any such dealings.
        </li>
        <li>
          If there is a dispute between Users, or between you and any third party, you understand
          and agree that ReturnPro is under no obligation to become involved. In the event that you
          have a dispute with any other user, you hereby releases ReturnPro, its affiliates and its
          respective agents, contractors, subcontractors, employees, officers, directors,
          shareholders, partners, and members, from all claims, demands, and damages (actual and
          consequential) of every kind or nature, known and unknown, suspected and unsuspected,
          disclosed and undisclosed, arising out of or in any way related to such disputes.
        </li>
        <li>
          In addition to the Site, ReturnPro may provide the following, but not limited to,
          “Warehouse and Fulfillment Services”, subject to a separate written agreement with User:
          (i) Receive return shipments from Users end buying customers, (ii) Provide storage
          facilities for Inventory in ReturnPro warehouse facilities, (iii)Value added services such
          as product condition grading, data wiping, refurbishment, and repair, (iv) Maintain ledger
          summaries of all orders s received or shipped which shall be made available from ReturnPro
          billing, (v) Providing packing materials or (vi) Reselling of returned inventory.
        </li>
      </ol>
    </li>
  )
}

const ThirdNumber = () => {
  return (
    <li>
      <b>Inventory Title, Risk of Loss And Insurance</b>
      <ol>
        <li>
          <b>Title.</b> You hereby agree that at no time during the period that User shipment and
          inventory (“<b>Products</b>”) are held by ReturnPro as inventory in ReturnPro’s warehouse
          (“<b>Inventory</b>”) will ReturnPro hold title, or any other rights of ownership in the
          Inventory. Title in Inventory will continue to be held by User until such time as the
          Products are delivered to its final destination.
        </li>
        <li>
          <b>Risk of Loss.</b> You hereby agree that at no time during the period that Products are
          held by ReturnPro as Inventory in the warehouse will ReturnPro carry the risk of loss in
          the Inventory. Risk of Loss in Inventory will continue to be held by User until such time
          as the Products are dispositioned to their destination. ReturnPro shall exercise
          commercially reasonable standards of care for all of User Products.
        </li>
        <li>
          <b>Insurance.</b> YOU HEREBY AGREE THAT IT IS YOUR RESPONSIBILITY, AT ALL TIMES, TO
          MAINTAIN AN INSURANCE POLICY THAT COVERS THE COST OF THE PRODUCTS HELD IN INVENTORY. You
          have the option of adding the warehouse as a designated storage location to your general
          inventory policy.
        </li>
        <li>
          <b>No Insurance.</b> You can choose not to insure Inventory. In this event, ReturnPro
          will, under no circumstances, be liable for any loss or damage to the Inventory stored at
          ReturnPro facilities. These events include, but are not limited to, theft, misuse, fire,
          natural disaster, or any other event.
        </li>
        <li>
          <b>Third Party Shipping Provider.</b> ReturnPro will not be responsible for any local
          customs charges, import taxes or duties or any similar charges related to User shipments.
          If any such charges become due as a result of User use of third-party shipping services
          and are charged to ReturnPro by any competent authority, User agrees to reimburse
          ReturnPro fully in respect of the same within 14 days of our demand.
        </li>
      </ol>
    </li>
  )
}

const FourthNumber = () => {
  return (
    <li>
      <b>Termination.</b> Subject to any payment obligations as set forth in Section 5, User has the
      right to terminate User’s account at any time by sending a cancellation request to{' '}
      <a className="underline" href="mailto:support@returnpro.com">
        support@returnpro.com
      </a>
      . ReturnPro may terminate User’s account access and this TOS at any time in its sole
      discretion by providing notice to the administrative email address associated with User
      Account. ReturnPro reserves the right to modify or discontinue, temporarily or permanently,
      the Services (or any part thereof). All of User Content (if any) may be permanently deleted by
      ReturnPro upon any termination of User account in its sole discretion. However, all accrued
      rights to payment and the terms of shall survive termination of this TOS.
    </li>
  )
}

const FifthNumber = () => {
  return (
    <li>
      <b>ReturnPro Payment Terms & Conditions </b>
      <ol>
        <li>The following Payment Terms and Conditions apply:</li>
        <ol>
          <li>
            Credit Card Payments. If User authorize ReturnPro to charge a credit card as a method of
            payment (a “<b>Credit Card Authorization</b>”), then User authorized ReturnPro, in its
            sole discretion, to charge the credit card account for the amount shown on any and all
            invoices presented to User without further notice to User. All credit card transactions
            will include credit card processing fee equal to 3.0% of the outstanding balance paid by
            credit card. User acknowledge that ReturnPro relies upon User Credit Card Authorization
            when determining whether to provide services to User. Therefore, in consideration for
            ReturnPro agreeing to rely, in whole or in part, upon User Credit Card Authorization as
            a means of payment, User irrevocably authorizes ReturnPro to charge User credit card for
            any charges related to any Services that has been performed. User may revoke User Credit
            Card Authorization for future Services by notifying ReturnPro in writing via{' '}
            <a className="underline" href="mailto:support@returnpro.com">
              support@returnpro.com
            </a>
            , provided, however such revocation shall not be effective for invoices that have
            already been shared with User or for foreseeable charges related to past, or current,
            Services at the time of User revocation. If User takes any action to frustrate a
            transaction authorized by the TOS, then ReturnPro, automatically and without further
            notice, revokes any credit terms or other payment accommodation which it might have
            previously afforded to User, accelerates User entire account balance, and User entire
            balance is immediately due and payable.{' '}
          </li>
          <li>
            <b>Contacting ReturnPro.</b> Transactions that we process using User bank account will
            be identiﬁed as &quot;ReturnPro&quot; (or similar identiﬁer) on the statement issued by
            User bank or other ﬁnancial institution holding User account. All questions relating to
            any transactions made using User bank account by us should be initially directed to us.
            User may contact us regarding User invoices or any payments made using User bank account
            and by writing to us at{' '}
            <a className="underline" href="mailto:support@returnpro.com">
              support@returnpro.com
            </a>
            . User may also view User billing history with ReturnPro at any time in the “Billing”
            tab of the ReturnPro Site.{' '}
          </li>
          <li>
            <b>Transaction Errors.</b> If User believe that any payment transaction initiated by
            ReturnPro (or its agent) with respect to User bank account is erroneous, or if User need
            more information about any such transaction, User should contact us at{' '}
            <a className="underline" href="mailto:support@returnpro.com">
              support@returnpro.com
            </a>
            . We reserve the right to cancel the ability to pay by bank account for any reason at
            any time.{' '}
          </li>
          <li>
            <b>No Offsets; Notice of Disputes.</b> User shall pay all charges in full, without any
            reduction based upon an alleged offset, counterclaim or other deduction. Full payment of
            charges shall be a condition precedent to User&apos;s right to assert an offset,
            counterclaim or other deduction. User must contest/dispute any charges in writing by
            email for ReturnPro’s review within 30 days of its receipt of an invoice that includes
            the contested charges.{' '}
          </li>
          <li>
            <b>Pricing Changes & Currency Fluctuations.</b> RetrunPro reserves the right to adjust
            its pricing for its Services, with 30 days’ notice via publication on the RetrunPro Site
            and/or direct notification to the User, at ReturnPro’s sole discretion. After the first
            90-days of Service, ReturnPro reserves the right to automatically revise pricing without
            notice if actual volume does not meet or exceed the initial volume estimates provided by
            the User prior to the effective date of the TOS as set forth in a purchase order, or
            other agreement entered by the parties, to a rate equal to actual volume. All dollar
            amounts stated in this TOS will be in US dollars unless otherwise specified.{' '}
          </li>
        </ol>
      </ol>
    </li>
  )
}

const SixthNumber = () => {
  return (
    <li>
      <b>Warranties and Indemnification.</b>
      <ol>
        <li>
          You represent and warrant to ReturnPro that you have full power and authority to enter
          into this TOS and to grant the licenses provided herein.{' '}
        </li>
        <li>
          You represent and warrant to ReturnPro that you own or have obtained all necessary rights
          to distribute and make available as specified in this TOS, any and all Inventory made
          available to ReturnPro.
        </li>
        <li>
          You agree to indemnify and hold harmless ReturnPro and its successors, parents,
          affiliates, assigns, subsidiaries and divisions, and each of their respective present and
          future officers, directors, employees, agents and/or clients, independent contractors,
          suppliers and business associates against and from any and all claims, demands, damages,
          actions, suits or proceedings (civil, criminal, administrative or investigative),
          settlements, costs, losses and expenses (including without limitation, reasonable
          attorneys’ and paralegal fees and costs), penalties, fines, judgments and liabilities or
          other relief sought of any kind and nature whatsoever (a “Claim”) arising from (i) any
          sale or resale of Inventory, including sales taxes, claims, demands, damages, actions,
          suits or proceedings of any kind or nature, including but not limited to claims, legal
          requirements, demands or actions of any kind arising from the collection of sales tax for
          the merchandise purchased, (ii) any misrepresentation or breach of this TOS, and/or (iii)
          any actual or alleged act of commission or omission by User’s end customer’s and/or its
          respective successors, parents, assigns, subsidiaries and divisions, and each of their
          respective present and future officers, directors, employees, agents and/or clients,
          independent contractors, suppliers and business associates.
        </li>
        <li>
          You will defend, indemnify and hold ReturnPro harmless from and against any and all Claims
          which ReturnPro may incur as a result of any third-party’s actions arising from or
          relating to (a) breach of any of your or your end customer’s representations, warranties
          or covenants herein, (b) sale or display of the Inventory, (c) use of the Inventory and
          any damages resulting therefrom, and (d) with respect to the Inventory, misappropriation
          of any third-party trade secret or infringement of any IP, privacy, moral or proprietary
          right of a third party. In the event of a dispute between two Users, Users release
          ReturnPro, directors, officers, employees and agents from Claims, whether actual or
          consequential, of every kind and nature, known and unknown, suspected and unsuspected,
          disclosed and undisclosed, arising out of or in any way connected with such dispute. This
          release includes any Claims brought by any individuals relating to misuse or unauthorized
          use of data User gives to ReturnPro.
        </li>
        <li>
          You agree to defend, indemnify and hold harmless ReturnPro, its affiliates, directors,
          officers, employees and agents to the fullest extent lawful against any and all Claims,
          liabilities, costs and expenses incurred (including, without limitation, all reasonable
          attorney fees and costs), as a consequence of any acts by User undertaken in connection
          with ReturnPro’s Site and Services, including without limitation, those arising out of any
          breach of any User representation or warranty, User’s obligations hereunder, any
          transaction attempted with the Service, or any dispute between you and any other user.
        </li>
      </ol>
    </li>
  )
}

const SeventhNumber = () => {
  return (
    <li>
      <b>Governing Law; Arbitration</b>
      <br />
      The TOS will be construed in accordance with and governed exclusively by the laws of the State
      of Florida applicable to agreements made among Florida residents and to be performed wholly
      within such jurisdiction, regardless of the parties’ actual domiciles, without regard to the
      conflicts of law provisions thereof. ANY CONTROVERSY OR CLAIM ARISING OUT OF OR RELATING TO
      THIS CONTRACT, OR THE BREACH THEREOF, SHALL BE SETTLED BY BINDING BILATERAL ARBITRATION
      ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION UNDER ITS COMMERCIAL ARBITRATION RULES,
      AND JUDGMENT ON THE AWARD RENDERED BY THE ARBITRATOR(S) MAY BE ENTERED IN ANY COURT HAVING
      COMPETENT JURISDICTION THEREOF.
    </li>
  )
}

const EighthNumber = () => {
  return (
    <li>
      <b>Severability</b>
      <br />
      If any provision of this TOS is held by a court of competent jurisdiction to be invalid or
      unenforceable for any reason, then such provision shall be enforced to the maximum extent
      permissible and the remainder of this TOS shall continue in full force and effect.
    </li>
  )
}

const NinthNumber = () => {
  return (
    <li>
      <b>Disclaimer of Warranties</b>
      <br />
      RETURNPRO IS NOT RESPONSIBLE FOR (A) ANY ERRORS OR OMISSIONS ARISING FROM THE LISTING OF
      INVENTORY THROUGH THE SERVICES, (B) LOSSES OR DAMAGES ARISING FROM THE USE OF INVENTORY BY ANY
      PARTY, AND/OR (C) ANY INVENTORY PROVIDED THROUGH OR IN CONJUNCTION WITH THE SERVICES,
      INCLUDING WITHOUT LIMITATION ANY FAILURES, DELAYS OR INTERRUPTIONS IN THE DELIVERY OF THE
      INVENTORY. EXCEPT AS EXPRESSLY SPECIFIED IN THIS TOS, RETURNPRO DOES NOT MAKE ANY WARRANTY OF
      ANY KIND IN CONNECTION WITH THE SUBJECT MATTER OF THIS TOS AND HEREBY SPECIFICALLY DISCLAIMS
      ANY AND ALL IMPLIED WARRANTIES, INCLUDING ALL IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT,
      MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE REGARDING SUCH SUBJECT MATTER. EXCEPT AS
      EXPRESSLY SPECIFIED IN THIS TOS, WITHOUT LIMITING THE FOREGOING, RETURNPRO DISCLAIMS ALL
      EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION THE WARRANTIES OR CONDITIONS OF
      MERCHANTABILITY, TITLE, NON-INFRINGEMENT, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT
      AND ACCURACY. RETURNPRO DOES NOT REPRESENT OR WARRANT THAT OPERATION OF SERVICE OR RETURNPRO
      TECHNOLOGIES WILL BE UNINTERRUPTED, ERROR-FREE OR SECURE. THE SITE AND SERVICES, INCLUDING ALL
      CONTENT, FUNCTIONS, MATERIALS AND INFORMATION ON OR ACCESSED THROUGH THE SITE OR SERVICES, ARE
      PROVIDED ON AN “AS IS, AS AVAILABLE” BASIS. RETURNPRO DISCLAIMS ALL WARRANTIES OF ANY KIND,
      EXPRESSED OR IMPLIED, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTY OF MERCHANTABILITY,
      FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, DATA ACCURACY SYSTEM INTEGRATION OR QUIET
      ENJOYMENT. RETURNPRO DOES NOT WARRANT THAT THE SERVICES, FUNCTIONS, FEATURES OR CONTENT WILL
      BE FUNCTIONAL, TIMELY, SECURE, UNINTERRUPTED OR ERROR FREE, OR THAT DEFECTS WILL BE CORRECTED.
      RETURNPRO MAKES NO WARRANTY THAT THE LISTED INVENTORY, SITE OR SERVICES WILL MEET USERS’
      REQUIREMENTS OR EXPECTATIONS, AND EXPRESSLY DISCLAIMS ANY WARRANTIES OR GUARANTEES. RETURNPRO
      MAKES NO WARRANTY REGARDING ANY LISTED INVENTORY OR SERVICES PURCHASED OR OBTAINED THROUGH THE
      SERVICES OR ANY TRANSACTIONS ENTERED INTO THROUGH THE SERVICES. NO ADVICE, INSTRUCTION OR
      INFORMATION RECEIVED BY A USER FROM RETURNPRO SHALL CREATE ANY WARRANTY BY RETURNPRO NOT
      EXPRESSLY MADE HEREIN. RETURNPRO EXPRESSLY DISCLAIMS ANY ENDORSEMENT OR WARRANTY OF ANY LISTED
      INVENTORY SOLD ON OR THROUGH THE SERVICES, AND ANY RESPONSIBILITY FOR ANY MISREPRESENTATIONS
      OR BREACHES COMMITTED BY ANOTHER USER.
    </li>
  )
}

const TenthNumber = () => {
  return (
    <li>
      <b>Limitation of Liability</b>
      <br />
      IN NO EVENT WILL RETURNPRO OR ITS AFFILIATES, OR THEIR LICENSORS, SERVICES PROVIDERS,
      EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR ANY DAMAGES OF ANY KIND, UNDER ANY
      LEGAL THEORY, ARISING FROM (a) ANY INJURY TO ANY PERSON OR PROPERTY CAUSED BY A LISTED
      INVENTORY(S) OR (b) DEFECTS IN SUCH INVENTORY(S) ON ANY THEORY OF LIABILITY, INCLUDING WITHOUT
      LIMITATION PRODUCT LIABILITY, NEGLIGENCE, BREACH OF CONTRACT OR BREACH OF WARRANTY. IN NO
      EVENT WILL RETURNPRO OR ITS AFFILIATES, OR THEIR LICENSORS, SERVICES PROVIDERS, EMPLOYEES,
      AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR
      CONSEQUENTIAL DAMAGES THAT ARE OR MAY BE DIRECTLY OR INDIRECTLY RELATED TO THE SERVICE, OR USE
      OR CONTENT OF INVENTORY, INCLUDING WITHOUT LIMITATION ANY MATERIALS AND FUNCTIONS RELATED
      THERETO, INCLUDING WITHOUT LIMITATION, LOSS OF REVENUE OR ANTICIPATED PROFITS OR LOST BUSINESS
      OR LOST SALES, EVEN IF RETURNPRO OR AN AUTHORIZED REPRESENTATIVE THEREOF HAS BEEN ADVISED OF
      THE POSSIBILITY OF SUCH DAMAGE. USER IS RESPONSIBLE FOR USER’S INVENTORY SOLD. IN NO EVENT
      WILL RETURNPRO’S TOTAL LIABILITY TO A USER FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION
      (WHETHER IN CONTRACT OR IN TORT) EXCEED THE AMOUNT OF FEES PAID BY THE USER TO RETURNPRO IN
      CONNECTION WITH THE DISPUTED INVENTORY, OR $100, whichever is greater. The Parties agree that
      the limitations of liability set forth will survive and apply even if any limited remedy
      specified in this Agreement is found to have failed its essential purpose. Some jurisdictions
      may not allow the limitation or exclusion of incidental or consequential damages, so the above
      limitation may not apply to certain Users.
    </li>
  )
}

const EleventhNumber = () => {
  return (
    <li>
      <b>Waiver</b>
      <br />
      IN NO EVENT WILL RETURNPRO OR ITS AFFILIATES, OR THEIR LICENSORS, SERVICES PROVIDERS,
      EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR ANY DAMAGES OF ANY KIND, UNDER ANY
      LEGAL THEORY, ARISING FROM (a) ANY INJURY TO ANY PERSON OR PROPERTY CAUSED BY A LISTED
      INVENTORY(S) OR (b) DEFECTS IN SUCH INVENTORY(S) ON ANY THEORY OF LIABILITY, INCLUDING WITHOUT
      LIMITATION PRODUCT LIABILITY, NEGLIGENCE, BREACH OF CONTRACT OR BREACH OF WARRANTY. IN NO
      EVENT WILL RETURNPRO OR ITS AFFILIATES, OR THEIR LICENSORS, SERVICES PROVIDERS, EMPLOYEES,
      AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR
      CONSEQUENTIAL DAMAGES THAT ARE OR MAY BE DIRECTLY OR INDIRECTLY RELATED TO THE SERVICE, OR USE
      OR CONTENT OF INVENTORY, INCLUDING WITHOUT LIMITATION ANY MATERIALS AND FUNCTIONS RELATED
      THERETO, INCLUDING WITHOUT LIMITATION, LOSS OF REVENUE OR ANTICIPATED PROFITS OR LOST BUSINESS
      OR LOST SALES, EVEN IF RETURNPRO OR AN AUTHORIZED REPRESENTATIVE THEREOF HAS BEEN ADVISED OF
      THE POSSIBILITY OF SUCH DAMAGE. USER IS RESPONSIBLE FOR USER’S INVENTORY SOLD. IN NO EVENT
      WILL RETURNPRO’S TOTAL LIABILITY TO A USER FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION
      (WHETHER IN CONTRACT OR IN TORT) EXCEED THE AMOUNT OF FEES PAID BY THE USER TO RETURNPRO IN
      CONNECTION WITH THE DISPUTED INVENTORY, OR $100, whichever is greater. The Parties agree that
      the limitations of liability set forth will survive and apply even if any limited remedy
      specified in this Agreement is found to have failed its essential purpose. Some jurisdictions
      may not allow the limitation or exclusion of incidental or consequential damages, so the above
      limitation may not apply to certain Users.
    </li>
  )
}

const TwelfthNumber = () => {
  return (
    <li>
      <b>Waiver of Class Actions</b>
      <br />
      USERS MAY ONLY RESOLVE DISPUTES WITH RETURNPRO ON AN INDIVIDUAL BASIS AND MAY NOT BRING A
      CLAIM AS A PLAINTIFF OR A CLASS MEMBER IN A CLASS, CONSOLIDATED, OR REPRESENTATIVE ACTION.
      CLASS ARBITRATIONS, CLASS ACTIONS, PRIVATE ATTORNEY GENERAL ACTIONS, AND CONSOLIDATION WITH
      OTHER ARBITRATIONS ARE NOT ALLOWED AND, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ARE
      EXPRESSLY WAIVED BY USERS.
    </li>
  )
}

const ThirteenthNumber = () => {
  return (
    <li>
      <b>Intellectual Property and Other Rights.</b> Except as set forth in this TOS, ReturnPro does
      not grant any other license or subscription under this TOS. ReturnPro and User each reserves
      any rights not expressly granted in this Agreement and disclaims all implied licenses,
      including, without limitation, implied licenses to trademarks, copyrights, trade secrets,
      patents and other forms of intellectual property (“IP”).
      <ol>
        <li>
          <b>ReturnPro IP.</b> ReturnPro retains all right, title and ownership in and to all
          elements of ReturnPro’s technology, the relations of these elements to the Services, and
          all transactions contemplated under this TOS, including without limitation any
          modifications or improvements to ReturnPro’s technology or the Services. ReturnPro is the
          sole and exclusive owner of ReturnPro’s technology, including the Site, unless otherwise
          stated. None of ReturnPro’s technology may be used in any way without the prior written
          consent of the ReturnPro. For example, no display or use of User’s Marks may be made
          without the express written permission of ReturnPro.{' '}
        </li>
        ReturnPro reserves the right to improve, enhance and modify ReturnPro’s technology,
        including the Site, providing this will not materially degrade the availability to Users of
        any feature of the Services. ReturnPro IP may be created by one of more ReturnPro employees
        in relation to the use or development of the Services or User Feedback regarding the Site or
        Services. User hereby irrevocably assigns to ReturnPro all right, title and interest User
        may acquire in any ReturnPro IP. ReturnPro retains right, title and interest in and to all
        data derived from the Services. ReturnPro may, at its sole option, seek legal protection for
        any form of ReturnPro’s technology, including the Site. All ReturnPro IP will be subject to
        the conditions of this TOS until this TOS is superseded, after which time all ReturnPro IP
        will be subject to the conditions of the superseding agreement.
        <li>
          <b>User IP.</b> All trademarks, trade names, logos, and service marks featured on the Site
          are the property of the owners of said IP. ReturnPro disclaims any associations or
          endorsements that may be implied through the use of the trademarks, trade names, logos or
          service marks of others.
        </li>
        <li>
          <b>Infringement notification.</b> Under U.S. federal law, knowingly misrepresenting that
          online material infringes copyright is subject to criminal prosecution for perjury and
          civil penalties, including monetary damages, court costs and attorneys’ fees. The full
          Digital Millennium Copyright Act of 1998 (DMCA) is available here:
          http://www.copyright.gov/legislation/dmca.pdf. If a copyright owner believes the said
          copyright is infringed by any feature of ReturnPro s Service, copyright owner (or person
          authorized to act on behalf of the owner) should notify ReturnPro immediately at
          support@ReturnPro.com, consider contacting an attorney in relation to User’s rights and
          responsibilities under the DMCA and other applicable laws, and provide the following
          information:
          <ol className="circle">
            <li className="circle">
              An electronic or physical signature of the person authorized to act on behalf of the
              copyright owner (if different from the owner);
            </li>
            <li className="circle">
              Identification of the copyrighted work that this person claims has been infringed;
            </li>
            <li className="circle">
              Identification of the material that this person claims to infringe on the copyrighted
              work and where the material is located on the Site;
            </li>
            <li className="circle">
              Information reasonably sufficient for ReturnPro to contact this person;
            </li>
            <li className="circle">
              A statement of good faith that use of the material that is claimed to infringe is not
              authorized by the copyright owner, this person (if different from the owner), or law,
              and
            </li>
            <li className="circle">
              A statement, made under penalty of perjury, that the above information is accurate and
              that this person is authorized to act on behalf of the copyright owner (if different
              from the owner). ReturnPro will attempt to review all infringement notices within
              twenty-four (24) hours of receipt. ReturnPro will then
            </li>
            <ol className="circle">
              <li className="circle">Remove or disable access to the material;</li>
              <li className="circle">
                Notify the respective User that ReturnPro has removed or disabled access to the
                material;
              </li>
              This procedure does not constitute legal advice but is instead the means whereby
              ReturnPro will comply with its rights and responsibilities under the DCMA.
            </ol>
          </ol>
        </li>
        <li>
          <b>Infringement counter-notification.</b> If User believes the material that was removed
          or to which access was disabled does not infringe on a copyright, or if User believes that
          User has the right to post and use such material from the copyright owner, the copyright
          owner’s agent, or pursuant to the law, User will have fourteen (14) days in which to
          provide ReturnPro with a counter-notice. The counter-notice must contain the following
          information:
          <ol className="circle">
            <li className="circle">A physical or electronic signature of User;</li>
            <li className="circle">
              Identification of the material that has been removed from the Site or the location to
              which access has been disabled in response to the claim of infringement;
            </li>
            <li className="circle">
              A statement of good faith that the removal of the material from the Site or the
              disabling of access to material on the Site was mistaken;
            </li>
            <li className="circle">
              A statement that User consents to the jurisdiction of the federal court for the
              judicial district in which the User’s address is located and that User will accept
              service of process from the person authorized to act on behalf of the copyright owner.
              ReturnPro will attempt to review all infringement counter-notices within twenty-four
              (24) hours of receipt. ReturnPro will then
            </li>
            <ol className="circle">
              <li className="circle">Restore or enable access to the material; and</li>
              <li className="circle">
                Notify the respective User that ReturnPro has restored or enabled access to the
                material; or
              </li>
              <li className="circle">
                Terminate the Agreement of any User that fails to comply with ReturnPro’s copyright
                infringement policy as set forth here.
              </li>
            </ol>
          </ol>
        </li>
      </ol>
    </li>
  )
}

const FourteenthNumber = () => {
  return (
    <li>
      <b>Subcontractors</b> We reserve the right to subcontract any of our rights or obligations
      under the TOS.
    </li>
  )
}

const FifteenthNumber = () => {
  return (
    <li>
      <b>Assignment</b>
      <br />
      This Agreement may not be assigned by User or by operation of law to any other person,
      persons, firms or corporations without the express written and duly executed approval of
      ReturnPro.
    </li>
  )
}

const SixteenthNumber = () => {
  return (
    <li>
      <b>Headings</b>
      <br />
      All section headings are for reference purposes only and in way define, limit, construe or
      describe the scope or extent of the respective sections.
    </li>
  )
}

const SeventeenthNumber = () => {
  return (
    <li>
      <b>Force Majeure</b>
      <br />
      ReturnPro shall not be liable for any delay in or the impairment of performance resulting in
      whole or in part from acts of God, labor disruptions, shortages, inability to produce product,
      supplies of raw materials, weather conditions, war or any other circumstances beyond the
      control of ReturnPro in the conduct of its business.
    </li>
  )
}

const EighteenthNumber = () => {
  return (
    <li>
      <b>No Third-Party Beneficiaries</b>
      <br />
      Except as otherwise expressly provided in this Agreement, nothing in this Agreement, express
      or implied, is intended or shall be construed to create any rights in, or confer any benefits
      upon, any person other than the Parties to this Agreement.
    </li>
  )
}

const NineteenthNumber = () => {
  return (
    <li>
      <b>ADDITIONAL TERMS AND CONDITIONS FOR USERS PURCHASING INVENTORY ON THE SITE</b>
      <br />
      The following terms and conditions apply in addition to the preceding terms and conditions of
      this Agreement.
    </li>
  )
}

const TwentiethNumber = () => {
  return (
    <li>
      <b>Site Regulations</b>
      <br />
      The technology, software, and data underlying the Site are the property of ReturnPro or
      ReturnPro’s affiliates or partners. User agrees not to copy, modify in any manner or form,
      rent, lease, loan, sell, assign, distribute, reverse engineer, grant a security interest in or
      otherwise transfer any right to the technology or software underlying the Site, or use a
      modified version of such technology or software for any purpose, including without limitation
      obtaining unauthorized access to the Site.
      <br />
      Without limiting the foregoing, User agrees not to use the Site for any of the following
      actions:
      <ol className="lower-alpha">
        <li>
          Defame, abuse, harass, stalk, threaten or otherwise violate the legal right of others;
        </li>
        <li>
          Publish, post, upload, email, distribute or disseminate (collectively, “Transmit”) any
          inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful content;
        </li>
        <li>
          Transmit files that contain viruses, corrupted files, or any other similar software or
          programs that may damage or adversely affect the operation of another person’s computer,
          our sites, any software or hardware, or telecommunications equipment;
        </li>
        <li>
          Advertise or offer to sell any goods or services for any commercial purpose unless User
          has ReturnPros written consent to do so;
        </li>
        <li>
          Transmit surveys, contests, pyramid schemes, spam, unsolicited advertising or promotional
          materials, or chain letters;
        </li>
        <li>
          Download any file that User knows, or reasonably should know, cannot be legally obtained
          in such manner;
        </li>
        <li>
          Falsify or delete any author attributions, legal or other proper notices or proprietary
          designations or labels of the origin or source of software or other material;
        </li>
        <li>
          Restrict or inhibit any other User from using and enjoying any public area within our
          sites;
        </li>
        <li>
          COLLECT, STORE OR TRY TO GAIN ACCESS TO PERSONAL INFORMATION ABOUT OTHER USERS, CUSTOMERS,
          SUPPLIERS OR ENTITIES THAT HAVE USED THE SITE;
        </li>
        <li>USE DATA ABOUT USERS, CUSTOMERS OR SUPPLIERS OR THIS SITE FOR PERSONAL GAIN;</li>
        <li>Interfere with or disrupt ReturnPros Site, servers or networks;</li>
        <li>
          Impersonate any person or entity, including but not limited to a ReturnPro representative,
          or falsely state or otherwise misrepresent User’s affiliation with a person or entity;
        </li>
        <li>
          Forge headers, manipulate identifiers or other data in order to disguise the origin of any
          content transmitted through our sites or manipulate User’s presence on our sites;
        </li>
        <li>
          Take any action that imposes an unreasonably or disproportionately large load on our
          infrastructure.
        </li>
        <li>Engage in any illegal or fraudulent activities.</li>
      </ol>
      ReturnPro reserves the right, without prior notice, to change a username that, in ReturnPro’s
      sole discretion, is considered obscene, indecent, abusive, or which might otherwise subject
      ReturnPro to public embarrassment or scorn. ReturnPro further reserves the right, without
      prior notice, to delete posts from and deny access to ReturnPro sites. Unauthorized access to
      ReturnPros Site will breach this Agreement and must be considered a violation of the law. User
      agrees not to access ReturnPro’s Site by any means other than the interface provided by
      ReturnPro and not use any automated means, including, without limitation, agents, robots,
      scripts, or spiders, to access, monitor or copy any part of ReturnPro ’s Site except as
      approved in advance and in writing by ReturnPro. Use of ReturnPro’s Site is subject to
      existing laws and due legal process. Nothing contained in this Agreement will limit
      ReturnPro’s right to comply with governmental, court and law enforcement requests or
      requirements relating to User’s use of ReturnPro’s Site.
    </li>
  )
}

const TwentyFirst = () => {
  return (
    <li>
      <b>Disclaimers.</b> EXCEPT AS OTHERWISE STATED HEREIN, RETURNPRO MAKES NO WARRANTY OF ANY KIND
      AND SPECIFICALLY DISCLAIMS ANY WARRANY, EXPRESS OR IMPLIED, AS TO THE INVENTORY SOLD
      HEREUNDER, OR THEIR MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. NO IMPLIED WARRANTY,
      ARISING BY USAGE OF TRADE, COURSE OF DEALING, OR COURSE OF PERFORMANCE, IS PROVIDED BY
      RETURNPRO. NOR WILL ANY WARRANTY ARISE BY OR IN CONNECTION WITH THIS AGREEMENT AND/OR
      RETURNPROS CONDUCT IN RELATION HERETO. IN NO EVENT SHALL RETURNPRO BE LIABLE ON ANY WARRANTY,
      FOR ANY INVENTORY/S SOLD ON THE RETURNPRO SITE. User accepts that all Inventory listed on the
      Site, refurbished or not, is sold “as is.” Unless ReturnPro agrees otherwise in writing, title
      and risk of loss will pass to User upon pickup at the origination point (F.O.B. origin). User,
      upon pickup of Inventory, releases ReturnPro of any fault, and forfeits any rights, associated
      with the Inventory or the shipment thereof.
    </li>
  )
}

const TwentySecond = () => {
  return (
    <li>
      <b>Entire Agreement</b> This TOS supersedes all prior and contemporaneous proposals,
      statements, sales materials or presentations, and agreements, both oral and written, and
      contains the entire understanding of the parties on the subject matter hereof.
    </li>
  )
}

const TwentyThird = () => {
  return (
    <li>
      <b>Information, Questions or Notification of Errors </b>
    </li>
  )
}

const TermsOfUse = () => {
  return (
    <section className="flex pt-28">
      <Container>
        <Typography.H2 className="pb-12">
          ReturnPro Terms of Service and User Agreement
        </Typography.H2>
        <div className="mb-4 text-xl font-normal text-nav-item-text">
          <ol>
            <FirstNumber />
            <TwoNumber />
            <ThirdNumber />
            <FourthNumber />
            <FifthNumber />
            <SixthNumber />
            <SeventhNumber />
            <EighthNumber />
            <NinthNumber />
            <TenthNumber />
            <EleventhNumber />
            <TwelfthNumber />
            <ThirteenthNumber />
            <FourteenthNumber />
            <FifteenthNumber />
            <SixteenthNumber />
            <SeventeenthNumber />
            <EighteenthNumber />
            <NineteenthNumber />
            <TwentiethNumber />
            <TwentyFirst />
            <TwentySecond />
            <TwentyThird />
            If User has any questions or comments concerning the TOS, please contact ReturnPro at
            the following address:{' '}
            <a className="underline" href="mailto:support@returnpro.com">
              support@returnpro.com
            </a>
          </ol>
        </div>
      </Container>
    </section>
  )
}

export default TermsOfUse

export const Head = () => <SEO title="Terms Of Use" />
